import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        VForm,
        {
          ref: "form",
          staticClass: "mt-5",
          attrs: { "lazy-validation": _vm.lazy },
          on: {
            submit: function ($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.checkForm()
            },
          },
          model: {
            value: _vm.valid,
            callback: function ($$v) {
              _vm.valid = $$v
            },
            expression: "valid",
          },
        },
        [
          _c(
            VRow,
            [
              _c(
                VCol,
                { attrs: { cols: "12", "data-test": "adduser:namebox" } },
                [
                  _c(VTextField, {
                    attrs: {
                      readonly: _vm.ReadOnly,
                      label: _vm.$t("name"),
                      rules: [_vm.required],
                      "data-test": "Admin:Basic:FirstName",
                    },
                    model: {
                      value: _vm.user.Name,
                      callback: function ($$v) {
                        _vm.$set(_vm.user, "Name", $$v)
                      },
                      expression: "user.Name",
                    },
                  }),
                ],
                1
              ),
              _c(
                VCol,
                { attrs: { cols: "12" } },
                [
                  _c(VTextField, {
                    attrs: {
                      readonly: _vm.ReadOnly,
                      label: _vm.$t("last_name"),
                      rules: [_vm.required],
                      "data-test": "Admin:Basic:LastName",
                    },
                    model: {
                      value: _vm.user.LastName,
                      callback: function ($$v) {
                        _vm.$set(_vm.user, "LastName", $$v)
                      },
                      expression: "user.LastName",
                    },
                  }),
                ],
                1
              ),
              _c(
                VCol,
                { attrs: { cols: "12" } },
                [
                  _c(VTextField, {
                    attrs: {
                      readonly: _vm.ReadOnly,
                      label: _vm.$t("email"),
                      rules: [_vm.required],
                      "data-test": "Admin:Basic:UserEmail",
                    },
                    on: {
                      keydown: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k($event.keyCode, "space", 32, $event.key, [
                            " ",
                            "Spacebar",
                          ])
                        ) {
                          return null
                        }
                        $event.preventDefault()
                      },
                    },
                    model: {
                      value: _vm.user.Email,
                      callback: function ($$v) {
                        _vm.$set(_vm.user, "Email", $$v)
                      },
                      expression: "user.Email",
                    },
                  }),
                ],
                1
              ),
              !_vm.edit
                ? _c(
                    VCol,
                    { attrs: { cols: "12" } },
                    [
                      _c(VSelect, {
                        attrs: {
                          readonly: _vm.ReadOnly,
                          color: "primary",
                          "item-color": "primary",
                          "item-value": "Index",
                          "item-text": "Name",
                          disabled:
                            _vm.PartnerSelected != null ||
                            _vm.UserToAddType != null,
                          items: _vm.user_type_options,
                          label: _vm.$t("user_type"),
                          "data-test": "Admin:Basic:UserType",
                        },
                        on: { change: _vm.handleChangeUserType },
                        model: {
                          value: _vm.user.Type,
                          callback: function ($$v) {
                            _vm.$set(_vm.user, "Type", $$v)
                          },
                          expression: "user.Type",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(VCol, { attrs: { cols: "12" } }, [
                _c(
                  "div",
                  [
                    _vm.user.Type === _vm.userTypeEnum.Partner &&
                    _vm.envManagerUser === "TRUE"
                      ? _c(VCheckbox, {
                          staticClass: "field",
                          attrs: {
                            disabled: _vm.disabledCheckUserIsManagerAndPartner,
                            color: "primary",
                            outlined: "",
                            dense: "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "label",
                                fn: function () {
                                  return [
                                    _c(
                                      "span",
                                      { staticStyle: { color: "var(--dark)" } },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("user_is_manager"))
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            1408405538
                          ),
                          model: {
                            value: _vm.userIsManagerAndPartner,
                            callback: function ($$v) {
                              _vm.userIsManagerAndPartner = $$v
                            },
                            expression: "userIsManagerAndPartner",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _vm.user.Type == _vm.userTypeEnum.Investor
                ? _c(
                    VCol,
                    { attrs: { cols: "12" } },
                    [
                      _c(VCombobox, {
                        attrs: {
                          "data-test": "Admin:Basic:UserNationality",
                          readonly: _vm.ReadOnly,
                          items: _vm.countries,
                          "item-text": "name",
                          "return-object": "",
                          rules: [_vm.required],
                          label: _vm.$t("nationality"),
                        },
                        on: { change: _vm.nationality_code_changed },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "selection",
                              fn: function (data) {
                                return [
                                  _c("img", {
                                    staticStyle: {
                                      width: "20px",
                                      height: "auto",
                                    },
                                    attrs: { src: data.item.flag, alt: "" },
                                  }),
                                  _c("span", { staticClass: "mx-2" }, [
                                    _vm._v(_vm._s(data.item.name)),
                                  ]),
                                ]
                              },
                            },
                            {
                              key: "item",
                              fn: function (data) {
                                return [
                                  _c("img", {
                                    staticStyle: {
                                      width: "20px",
                                      height: "auto",
                                    },
                                    attrs: { src: data.item.flag, alt: "" },
                                  }),
                                  _c("span", { staticClass: "mx-2" }, [
                                    _vm._v(_vm._s(data.item.name)),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3149773820
                        ),
                        model: {
                          value: _vm.nationalitySelected,
                          callback: function ($$v) {
                            _vm.nationalitySelected = $$v
                          },
                          expression: "nationalitySelected",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.user.Type == _vm.userTypeEnum.Investor
                ? _c(
                    VCol,
                    { attrs: { cols: "12" } },
                    [
                      _c(VSelect, {
                        attrs: {
                          readonly: _vm.ReadOnly,
                          rules: [_vm.selectRequired],
                          items: _vm.currencyOptions,
                          "item-text": "text",
                          "item-value": "value",
                          label: _vm.$t("currency"),
                          "data-test": "Admin:Basic:CurrencyPreference",
                        },
                        model: {
                          value: _vm.currencyPreference,
                          callback: function ($$v) {
                            _vm.currencyPreference = $$v
                          },
                          expression: "currencyPreference",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.user.Type == _vm.userTypeEnum.Investor
                ? _c(
                    VCol,
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "p",
                        {
                          staticClass: "mx-auto",
                          staticStyle: { color: "var(--dark)" },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("account_type")) + " ")]
                      ),
                      _c(
                        VRadioGroup,
                        {
                          attrs: { center: "", readonly: _vm.ReadOnly },
                          model: {
                            value: _vm.user.JuridicalType,
                            callback: function ($$v) {
                              _vm.$set(_vm.user, "JuridicalType", $$v)
                            },
                            expression: "user.JuridicalType",
                          },
                        },
                        [
                          _c(VRadio, {
                            attrs: {
                              value: 0,
                              "data-test": "Admin:Basic:JuricalTypePhysical",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: { color: "var(--dark)" },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("fisical_person"))
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              1066688523
                            ),
                          }),
                          _c(VRadio, {
                            attrs: {
                              value: 1,
                              "data-test": "Admin:Basic:JuricalTypeJuridical",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: { color: "var(--dark)" },
                                        },
                                        [_vm._v(_vm._s(_vm.$t("legal_person")))]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              1686139251
                            ),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.user.Type == _vm.userTypeEnum.Investor
                ? _c(
                    VCol,
                    { attrs: { cols: "12" } },
                    [
                      _vm.user.Nationality == "Brazil"
                        ? _c(VTextField, {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value:
                                  _vm.user.JuridicalType ==
                                  _vm.JuridicalTypeEnum.NaturalPerson
                                    ? "###.###.###-##"
                                    : "##.###.###/####-##",
                                expression:
                                  "\n            user.JuridicalType == JuridicalTypeEnum.NaturalPerson\n              ? '###.###.###-##'\n              : '##.###.###/####-##'\n          ",
                              },
                            ],
                            attrs: {
                              readonly: _vm.ReadOnly,
                              dense: "",
                              type: "tel",
                              rules: [_vm.required, _vm.social_number_valid],
                              label:
                                _vm.user.JuridicalType ==
                                _vm.JuridicalTypeEnum.NaturalPerson
                                  ? _vm.$t("cpf")
                                  : _vm.$t("company_id"),
                              "data-test": "Admin:Basic:UserSocialNumber",
                            },
                            on: {
                              keydown: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "space",
                                    32,
                                    $event.key,
                                    [" ", "Spacebar"]
                                  )
                                ) {
                                  return null
                                }
                                $event.preventDefault()
                              },
                            },
                            model: {
                              value: _vm.user.SocialNumber,
                              callback: function ($$v) {
                                _vm.$set(_vm.user, "SocialNumber", $$v)
                              },
                              expression: "user.SocialNumber",
                            },
                          })
                        : _c(VTextField, {
                            attrs: {
                              readonly: _vm.ReadOnly,
                              dense: "",
                              type: "tel",
                              rules: [_vm.required],
                              label:
                                _vm.user.JuridicalType ==
                                _vm.JuridicalTypeEnum.NaturalPerson
                                  ? _vm.$t("passport")
                                  : _vm.$t("company_id"),
                            },
                            on: {
                              keydown: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "space",
                                    32,
                                    $event.key,
                                    [" ", "Spacebar"]
                                  )
                                ) {
                                  return null
                                }
                                $event.preventDefault()
                              },
                            },
                            model: {
                              value: _vm.user.SocialNumber,
                              callback: function ($$v) {
                                _vm.$set(_vm.user, "SocialNumber", $$v)
                              },
                              expression: "user.SocialNumber",
                            },
                          }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.user.Type === _vm.userTypeEnum.ManagerUser ||
              _vm.userIsManagerAndPartner
                ? _c(
                    VCol,
                    { attrs: { cols: "12" } },
                    [
                      _vm.user.Type === _vm.userTypeEnum.ManagerUser
                        ? _c(VTextField, {
                            attrs: {
                              readonly: _vm.ReadOnly,
                              type: "tel",
                              label: _vm.$t("celphone"),
                              "data-test": "Admin:Basic:PhoneInputManager",
                            },
                            on: {
                              keydown: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "space",
                                    32,
                                    $event.key,
                                    [" ", "Spacebar"]
                                  )
                                ) {
                                  return null
                                }
                                $event.preventDefault()
                              },
                            },
                            model: {
                              value: _vm.user.CelPhone,
                              callback: function ($$v) {
                                _vm.$set(_vm.user, "CelPhone", $$v)
                              },
                              expression: "user.CelPhone",
                            },
                          })
                        : _vm._e(),
                      _c(VAutocomplete, {
                        attrs: {
                          readonly: _vm.ReadOnly,
                          rules: [_vm.required],
                          items: _vm.managerPartnerList,
                          "item-text": "name",
                          "item-value": "id",
                          label: _vm.$t("manager_company"),
                          disabled: _vm.disabledManagerPartner,
                          "data-test": "Admin:Basic:CompanyInputManager",
                        },
                        on: { keyup: _vm.managerPartnerChanged },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "no-data",
                              fn: function () {
                                return [
                                  _c(
                                    VListItem,
                                    [
                                      _c(VListItemTitle, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.managerPartnerFallback
                                                ? _vm.$t(
                                                    "enter_manager_company"
                                                  )
                                                : _vm.$t("profession_not_found")
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          3283227281
                        ),
                        model: {
                          value: _vm.user.ManagerPartnerId,
                          callback: function ($$v) {
                            _vm.$set(_vm.user, "ManagerPartnerId", $$v)
                          },
                          expression: "user.ManagerPartnerId",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.user.Type == _vm.userTypeEnum.Banker ||
              _vm.user.Type == _vm.userTypeEnum.Partner
                ? _c(
                    VCol,
                    { attrs: { cols: "12" } },
                    [
                      _c(VTextField, {
                        attrs: {
                          readonly: _vm.ReadOnly,
                          type: "tel",
                          label: _vm.$t("celphone"),
                          "data-test": "Basic:PhoneInput",
                        },
                        on: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k($event.keyCode, "space", 32, $event.key, [
                                " ",
                                "Spacebar",
                              ])
                            ) {
                              return null
                            }
                            $event.preventDefault()
                          },
                        },
                        model: {
                          value: _vm.user.CelPhone,
                          callback: function ($$v) {
                            _vm.$set(_vm.user, "CelPhone", $$v)
                          },
                          expression: "user.CelPhone",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.user.Type == _vm.userTypeEnum.Partner
                ? _c(VCol, { attrs: { cols: "12" } }, [
                    _c(
                      "div",
                      { staticClass: "add-company-partner-container" },
                      [
                        _c(VAutocomplete, {
                          attrs: {
                            readonly: _vm.ReadOnly,
                            rules: [_vm.required],
                            items: _vm.partnersB2bList,
                            "item-text": "name",
                            "item-value": "id",
                            label: _vm.$tc("company_partner", 1),
                            disabled: _vm.disabledPartnerB2bOptions,
                            "data-test": "Basic:PartnerB2BAutoComplete",
                          },
                          on: { change: _vm.partnerB2bChanged },
                          model: {
                            value: _vm.user.PartnerB2bId,
                            callback: function ($$v) {
                              _vm.$set(_vm.user, "PartnerB2bId", $$v)
                            },
                            expression: "user.PartnerB2bId",
                          },
                        }),
                        !_vm.disabledPartnerB2bOptions
                          ? _c(
                              "button",
                              {
                                staticClass: "add-company-partner-button",
                                attrs: { type: "button" },
                                on: {
                                  click: function () {
                                    return (_vm.dialogAddPartnerB2bCompany = true)
                                  },
                                },
                              },
                              [
                                _c("font-awesome-icon", {
                                  staticClass:
                                    "add-company-partner-container-icon",
                                  attrs: { icon: "fa-solid fa-plus" },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.user.Type === _vm.userTypeEnum.Banker
                ? _c(
                    VCol,
                    { attrs: { cols: "12" } },
                    [
                      _c(VTextField, {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: "###.###.###-##",
                            expression: "'###.###.###-##'",
                          },
                        ],
                        attrs: {
                          readonly: _vm.ReadOnly,
                          dense: "",
                          type: "tel",
                          rules: [_vm.required, _vm.social_number_valid],
                          label: _vm.$t("cpf"),
                          "data-test": "Admin:Basic:UserBankerSocialNumber",
                        },
                        on: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k($event.keyCode, "space", 32, $event.key, [
                                " ",
                                "Spacebar",
                              ])
                            ) {
                              return null
                            }
                            $event.preventDefault()
                          },
                        },
                        model: {
                          value: _vm.user.SocialNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.user, "SocialNumber", $$v)
                          },
                          expression: "user.SocialNumber",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.user.Type === _vm.userTypeEnum.Admin
                ? _c(
                    VCol,
                    { attrs: { cols: "12" } },
                    [
                      _c(VSelect, {
                        attrs: {
                          readonly: _vm.ReadOnly,
                          items: _vm.adminTypes,
                          "item-text": "text",
                          label: _vm.$t("adminType"),
                        },
                        model: {
                          value: _vm.user.AdminType,
                          callback: function ($$v) {
                            _vm.$set(_vm.user, "AdminType", $$v)
                          },
                          expression: "user.AdminType",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.edit
                ? _c(
                    VCol,
                    {
                      attrs: {
                        cols: "12",
                        md: "6",
                        "data-test": "adduser:passbox",
                      },
                    },
                    [
                      _c(VTextField, {
                        attrs: {
                          readonly: _vm.ReadOnly,
                          label: _vm.$t("password"),
                          "append-icon": _vm.show1 ? "mdi-eye" : "mdi-eye-off",
                          rules: [
                            _vm.required,
                            _vm.min_valid,
                            _vm.uppercase_required,
                            _vm.lowercase_required,
                            _vm.number_required,
                            _vm.special_required,
                            _vm.accent_valid,
                            _vm.passwordMinCharacter,
                          ],
                          type: _vm.show1 ? "text" : "password",
                          counter: "",
                          "data-test": "Admin:Basic:UserPassword",
                        },
                        on: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k($event.keyCode, "space", 32, $event.key, [
                                " ",
                                "Spacebar",
                              ])
                            ) {
                              return null
                            }
                            $event.preventDefault()
                          },
                          keyup: _vm.handleChangePassword,
                          "click:append": function ($event) {
                            _vm.show1 = !_vm.show1
                          },
                        },
                        model: {
                          value: _vm.user.UserPassword,
                          callback: function ($$v) {
                            _vm.$set(_vm.user, "UserPassword", $$v)
                          },
                          expression: "user.UserPassword",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.edit
                ? _c(
                    VCol,
                    {
                      attrs: {
                        cols: "12",
                        md: "6",
                        "data-test": "adduser:repassbox",
                      },
                    },
                    [
                      _c(VTextField, {
                        attrs: {
                          readonly: _vm.ReadOnly,
                          label: _vm.$t("re_password"),
                          "append-icon": _vm.show2 ? "mdi-eye" : "mdi-eye-off",
                          rules: [_vm.required, _vm.passwordsMatch],
                          type: _vm.show2 ? "text" : "password",
                          "data-test": "Admin:Basic:UserConfirmPassword",
                        },
                        on: {
                          "click:append": function ($event) {
                            _vm.show2 = !_vm.show2
                          },
                        },
                        model: {
                          value: _vm.rePassword,
                          callback: function ($$v) {
                            _vm.rePassword = $$v
                          },
                          expression: "rePassword",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.edit
                ? _c(
                    VCol,
                    { attrs: { cols: "12" } },
                    [
                      _c("ValidateStrengthPassword", {
                        attrs: { userPassword: _vm.passwordToValidateStrength },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.user.Type == _vm.userTypeEnum.Investor
                ? _c(
                    VCol,
                    { attrs: { cols: "12" } },
                    [
                      _c(VSelect, {
                        attrs: {
                          readonly: _vm.ReadOnly,
                          rules: [_vm.selectRequired],
                          items: _vm.eSimNao,
                          "item-text": "text",
                          "item-value": "value",
                          label: _vm.$t("signed_nda"),
                          "data-test": "Admin:Basic:SignedNda",
                        },
                        model: {
                          value: _vm.selected_nda,
                          callback: function ($$v) {
                            _vm.selected_nda = $$v
                          },
                          expression: "selected_nda",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.user.Type == _vm.userTypeEnum.Investor
                ? _c(
                    VCol,
                    { attrs: { cols: "12" } },
                    [
                      _c(VSelect, {
                        attrs: {
                          "data-test": "Admin:Basic:SignedContract",
                          readonly: _vm.ReadOnly,
                          rules: [_vm.selectRequired],
                          items: _vm.eSimNao,
                          "item-text": "text",
                          "item-value": "value",
                          label: _vm.$t("signed_contract"),
                        },
                        model: {
                          value: _vm.selected_contract,
                          callback: function ($$v) {
                            _vm.selected_contract = $$v
                          },
                          expression: "selected_contract",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              (_vm.user.Type == _vm.userTypeEnum.Banker && !_vm.edit) ||
              _vm.user.Type == _vm.userTypeEnum.Investor
                ? _c(
                    VCol,
                    { attrs: { cols: "12" } },
                    [
                      _c(VAutocomplete, {
                        attrs: {
                          readonly: _vm.ReadOnly,
                          rules: [_vm.required],
                          items: _vm.partnersB2bList,
                          "item-text": "name",
                          "item-value": "id",
                          label: _vm.$tc("partner", 1),
                          "data-test": "Admin:Basic:UserPartner",
                        },
                        on: { change: _vm.partnerB2bChanged },
                        model: {
                          value: _vm.user.PartnerB2bId,
                          callback: function ($$v) {
                            _vm.$set(_vm.user, "PartnerB2bId", $$v)
                          },
                          expression: "user.PartnerB2bId",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.user.Type == _vm.userTypeEnum.Investor &&
              _vm.user.PartnerB2bId
                ? _c(
                    VCol,
                    { attrs: { cols: "12" } },
                    [
                      _c(VAutocomplete, {
                        attrs: {
                          readonly: _vm.ReadOnly,
                          "no-data-text": _vm.$t("no_banker"),
                          items: _vm.bankersFiltered,
                          "item-text": "Email",
                          "item-value": "Id",
                          label: _vm.$tc("banker", 1),
                          "data-test": "Admin:Basic:Banker",
                        },
                        model: {
                          value: _vm.user.BankerId,
                          callback: function ($$v) {
                            _vm.$set(_vm.user, "BankerId", $$v)
                          },
                          expression: "user.BankerId",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.user.Type == _vm.userTypeEnum.Investor
                ? _c(
                    VCol,
                    { attrs: { cols: "12" } },
                    [
                      _c("p", { staticClass: "mb-0" }, [
                        _vm._v(_vm._s(_vm.$t("is_alpha"))),
                      ]),
                      _c(
                        VRadioGroup,
                        {
                          attrs: { readonly: _vm.ReadOnly, row: "" },
                          model: {
                            value: _vm.user.IsAlpha,
                            callback: function ($$v) {
                              _vm.$set(_vm.user, "IsAlpha", $$v)
                            },
                            expression: "user.IsAlpha",
                          },
                        },
                        [
                          _c(VRadio, {
                            attrs: {
                              "data-test": "Admin:Basic:AlphaUserTrue",
                              label: _vm.$t("yes"),
                              value: true,
                            },
                          }),
                          _c(VRadio, {
                            attrs: { label: _vm.$t("no"), value: false },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.user.Type == _vm.userTypeEnum.Investor
                ? _c(
                    VCol,
                    { attrs: { cols: "12" } },
                    [
                      _c("p", { staticClass: "mb-0" }, [
                        _vm._v(_vm._s(_vm.$t("profile_is_done"))),
                      ]),
                      _c(
                        VRadioGroup,
                        {
                          attrs: { readonly: _vm.ReadOnly, row: "" },
                          model: {
                            value: _vm.user.ProfileDone,
                            callback: function ($$v) {
                              _vm.$set(_vm.user, "ProfileDone", $$v)
                            },
                            expression: "user.ProfileDone",
                          },
                        },
                        [
                          _c(VRadio, {
                            attrs: {
                              "data-test": "Admin:Basic:ProfileDone",
                              label: _vm.$t("yes"),
                              value: true,
                            },
                          }),
                          _c(VRadio, {
                            attrs: { label: _vm.$t("no"), value: false },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.user.Type == _vm.userTypeEnum.Investor
                ? _c(
                    VCol,
                    { attrs: { cols: "12" } },
                    [
                      _c("p", { staticClass: "mb-0" }, [
                        _vm._v(_vm._s(_vm.$t("user_created_use_terms"))),
                      ]),
                      _c(
                        VRadioGroup,
                        {
                          attrs: {
                            readonly: _vm.ReadOnly,
                            row: "",
                            disabled: _vm.disabledUseTerms,
                          },
                          model: {
                            value: _vm.user.UseTerms,
                            callback: function ($$v) {
                              _vm.$set(_vm.user, "UseTerms", $$v)
                            },
                            expression: "user.UseTerms",
                          },
                        },
                        [
                          _c(VRadio, {
                            attrs: { label: _vm.$t("yes"), value: true },
                          }),
                          _c(VRadio, {
                            attrs: { label: _vm.$t("no"), value: false },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm.user.Type == _vm.userTypeEnum.Investor && _vm.edit
            ? _c(
                VExpansionPanels,
                [
                  _c(
                    VExpansionPanel,
                    [
                      _c(VExpansionPanelHeader, [
                        _vm._v(" " + _vm._s(_vm.$t("contact_profile")) + " "),
                      ]),
                      _c(
                        VExpansionPanelContent,
                        [
                          _vm.user.Type == _vm.userTypeEnum.Investor
                            ? _c(
                                VRow,
                                [
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "4" } },
                                    [
                                      _c(VTextField, {
                                        directives: [
                                          {
                                            name: "mask",
                                            rawName: "v-mask",
                                            value: "##/##/####",
                                            expression: "'##/##/####'",
                                          },
                                        ],
                                        attrs: {
                                          readonly: _vm.ReadOnly,
                                          hint: "dd/mm/yyyy",
                                          label:
                                            _vm.user.JuridicalType ==
                                            _vm.JuridicalTypeEnum.NaturalPerson
                                              ? _vm.$t("birth_date")
                                              : _vm.$t("establishment_date"),
                                          "prepend-inner-icon": "mdi-calendar",
                                          rules: [
                                            _vm.validDate,
                                            _vm.min18Years,
                                          ],
                                        },
                                        model: {
                                          value: _vm.date,
                                          callback: function ($$v) {
                                            _vm.date = $$v
                                          },
                                          expression: "date",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "4" } },
                                    [
                                      _c(VTextField, {
                                        attrs: {
                                          readonly: _vm.ReadOnly,
                                          rules: [_vm.required],
                                          type: "tel",
                                          label: _vm.$t("celphone"),
                                        },
                                        on: {
                                          keydown: function ($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "space",
                                                32,
                                                $event.key,
                                                [" ", "Spacebar"]
                                              )
                                            ) {
                                              return null
                                            }
                                            $event.preventDefault()
                                          },
                                        },
                                        model: {
                                          value: _vm.user.CelPhone,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.user, "CelPhone", $$v)
                                          },
                                          expression: "user.CelPhone",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "4" } },
                                    [
                                      _c(VTextField, {
                                        attrs: {
                                          readonly: _vm.ReadOnly,
                                          label: _vm.$t("home_phone"),
                                        },
                                        on: {
                                          keydown: function ($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "space",
                                                32,
                                                $event.key,
                                                [" ", "Spacebar"]
                                              )
                                            ) {
                                              return null
                                            }
                                            $event.preventDefault()
                                          },
                                        },
                                        model: {
                                          value: _vm.user.HomePhone,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.user, "HomePhone", $$v)
                                          },
                                          expression: "user.HomePhone",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "8" } },
                                    [
                                      _c(VTextField, {
                                        staticClass: "purple-input",
                                        attrs: {
                                          readonly: _vm.ReadOnly,
                                          label: _vm.$t("address"),
                                        },
                                        model: {
                                          value: _vm.user.Address,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.user, "Address", $$v)
                                          },
                                          expression: "user.Address",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "2" } },
                                    [
                                      _c(VTextField, {
                                        staticClass: "purple-input",
                                        attrs: {
                                          readonly: _vm.ReadOnly,
                                          label: _vm.$t("number"),
                                        },
                                        model: {
                                          value: _vm.user.AddressNumber,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.user,
                                              "AddressNumber",
                                              $$v
                                            )
                                          },
                                          expression: "user.AddressNumber",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "2" } },
                                    [
                                      _c(VTextField, {
                                        staticClass: "purple-input",
                                        attrs: {
                                          readonly: _vm.ReadOnly,
                                          label: _vm.$t("complement"),
                                        },
                                        model: {
                                          value: _vm.user.AddressComplement,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.user,
                                              "AddressComplement",
                                              $$v
                                            )
                                          },
                                          expression: "user.AddressComplement",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "6" } },
                                    [
                                      _c(VTextField, {
                                        staticClass: "purple-input",
                                        attrs: {
                                          readonly: _vm.ReadOnly,
                                          label: _vm.$t("state"),
                                        },
                                        model: {
                                          value: _vm.user.State,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.user, "State", $$v)
                                          },
                                          expression: "user.State",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "6" } },
                                    [
                                      _c(VTextField, {
                                        staticClass: "purple-input",
                                        attrs: {
                                          readonly: _vm.ReadOnly,
                                          label: _vm.$t("neighborhood"),
                                        },
                                        model: {
                                          value: _vm.user.Neighborhood,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.user,
                                              "Neighborhood",
                                              $$v
                                            )
                                          },
                                          expression: "user.Neighborhood",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "4" } },
                                    [
                                      _c(VTextField, {
                                        staticClass: "purple-input",
                                        attrs: {
                                          readonly: _vm.ReadOnly,
                                          label: _vm.$t("country"),
                                        },
                                        model: {
                                          value: _vm.user.Country,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.user, "Country", $$v)
                                          },
                                          expression: "user.Country",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "4" } },
                                    [
                                      _c(VTextField, {
                                        staticClass: "purple-input",
                                        attrs: {
                                          readonly: _vm.ReadOnly,
                                          label: _vm.$t("city"),
                                        },
                                        model: {
                                          value: _vm.user.City,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.user, "City", $$v)
                                          },
                                          expression: "user.City",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "4" } },
                                    [
                                      _c(VTextField, {
                                        staticClass: "purple-input",
                                        attrs: {
                                          readonly: _vm.ReadOnly,
                                          label: _vm.$t("post_code"),
                                        },
                                        model: {
                                          value: _vm.user.PostCode,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.user, "PostCode", $$v)
                                          },
                                          expression: "user.PostCode",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12" } },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass: "mx-auto",
                                          staticStyle: { color: "var(--dark)" },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("account_type")) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        VRadioGroup,
                                        {
                                          attrs: {
                                            center: "",
                                            readonly: _vm.ReadOnly,
                                          },
                                          model: {
                                            value: _vm.user.JuridicalType,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.user,
                                                "JuridicalType",
                                                $$v
                                              )
                                            },
                                            expression: "user.JuridicalType",
                                          },
                                        },
                                        [
                                          _c(VRadio, {
                                            attrs: {
                                              value:
                                                _vm.JuridicalTypeEnum
                                                  .NaturalPerson,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "label",
                                                  fn: function () {
                                                    return [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            color:
                                                              "var(--dark)",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "fisical_person"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ],
                                              null,
                                              false,
                                              1066688523
                                            ),
                                          }),
                                          _c(VRadio, {
                                            attrs: {
                                              value:
                                                _vm.JuridicalTypeEnum
                                                  .LegalPerson,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "label",
                                                  fn: function () {
                                                    return [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            color:
                                                              "var(--dark)",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "legal_person"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ],
                                              null,
                                              false,
                                              1686139251
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.user.JuridicalType == _vm.JuridicalTypeEnum.NaturalPerson
                    ? _c(
                        VExpansionPanel,
                        [
                          _c(VExpansionPanelHeader, [
                            _vm._v(
                              " " + _vm._s(_vm.$t("patrimonial_infos")) + " "
                            ),
                          ]),
                          _c(
                            VExpansionPanelContent,
                            [
                              _vm.user.Type == _vm.userTypeEnum.Investor
                                ? _c(
                                    VRow,
                                    [
                                      _c(VTextField, {
                                        directives: [
                                          {
                                            name: "money",
                                            rawName: "v-money",
                                            value: _vm.money,
                                            expression: "money",
                                          },
                                        ],
                                        staticClass: "purple-input",
                                        attrs: {
                                          label: _vm.$t("total_assets"),
                                        },
                                        model: {
                                          value: _vm.user.TotalAssets,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.user,
                                              "TotalAssets",
                                              $$v
                                            )
                                          },
                                          expression: "user.TotalAssets",
                                        },
                                      }),
                                      _c(VCol, { attrs: { cols: "12" } }),
                                      _c(VCol, { attrs: { cols: "12" } }, [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              color: "var(--dark)",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("assets_origins")
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "mt-4 mx-12" },
                                          [
                                            _c(
                                              VRow,
                                              { attrs: { align: "center" } },
                                              _vm._l(
                                                _vm.asset_options,
                                                function (a, i) {
                                                  return _c(
                                                    VCol,
                                                    {
                                                      key: i,
                                                      staticClass: "my-0 py-0",
                                                      attrs: {
                                                        cols: "12",
                                                        md: "6",
                                                      },
                                                    },
                                                    [
                                                      _c(VCheckbox, {
                                                        staticClass:
                                                          "my-0 py-0",
                                                        attrs: {
                                                          readonly:
                                                            _vm.ReadOnly,
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "label",
                                                              fn: function () {
                                                                return [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          color:
                                                                            "var(--dark)",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          a.Text
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              },
                                                              proxy: true,
                                                            },
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                        model: {
                                                          value: a.Marked,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              a,
                                                              "Marked",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "a.Marked",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.user.JuridicalType == _vm.JuridicalTypeEnum.NaturalPerson
                    ? _c(
                        VExpansionPanel,
                        [
                          _c(VExpansionPanelHeader, [
                            _vm._v(" " + _vm._s(_vm.$t("personal_docs")) + " "),
                          ]),
                          _c(
                            VExpansionPanelContent,
                            [
                              _vm.user.Type == _vm.userTypeEnum.Investor
                                ? _c(
                                    VRow,
                                    [
                                      _c(
                                        VCol,
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("document_type")
                                                ) +
                                                " "
                                            ),
                                          ]),
                                          _c(
                                            VRow,
                                            { staticClass: "my-4 mx-12" },
                                            [
                                              _c(
                                                VRadioGroup,
                                                {
                                                  attrs: {
                                                    center: "",
                                                    readonly: _vm.ReadOnly,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.user.DocumentType,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.user,
                                                        "DocumentType",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "user.DocumentType",
                                                  },
                                                },
                                                [
                                                  _c(VRadio, {
                                                    attrs: { value: 0 },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "label",
                                                          fn: function () {
                                                            return [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticStyle: {
                                                                    color:
                                                                      "var(--dark)",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "rg"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      4117849935
                                                    ),
                                                  }),
                                                  _c(VRadio, {
                                                    attrs: { value: 1 },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "label",
                                                          fn: function () {
                                                            return [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticStyle: {
                                                                    color:
                                                                      "var(--dark)",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "cnh"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      3985088063
                                                    ),
                                                  }),
                                                  _c(VRadio, {
                                                    attrs: { value: 2 },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "label",
                                                          fn: function () {
                                                            return [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticStyle: {
                                                                    color:
                                                                      "var(--dark)",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "passport"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      3524110290
                                                    ),
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        VCol,
                                        { attrs: { cols: "12", md: "3" } },
                                        [
                                          _c(VTextField, {
                                            staticClass: "purple-input",
                                            attrs: {
                                              readonly: _vm.ReadOnly,
                                              label: _vm.$t("document_number"),
                                            },
                                            model: {
                                              value: _vm.user.DocumentNumber,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.user,
                                                  "DocumentNumber",
                                                  $$v
                                                )
                                              },
                                              expression: "user.DocumentNumber",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        VCol,
                                        { attrs: { cols: "12", md: "3" } },
                                        [
                                          _c(VTextField, {
                                            staticClass: "purple-input",
                                            attrs: {
                                              readonly: _vm.ReadOnly,
                                              label: _vm.$t("issuing_body"),
                                            },
                                            model: {
                                              value: _vm.user.Issuer,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.user,
                                                  "Issuer",
                                                  $$v
                                                )
                                              },
                                              expression: "user.Issuer",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        VCol,
                                        { attrs: { cols: "12", md: "3" } },
                                        [
                                          _c(VTextField, {
                                            staticClass: "purple-input",
                                            attrs: {
                                              readonly: _vm.ReadOnly,
                                              label: _vm.$t("state"),
                                            },
                                            model: {
                                              value: _vm.user.IssuerState,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.user,
                                                  "IssuerState",
                                                  $$v
                                                )
                                              },
                                              expression: "user.IssuerState",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        VCol,
                                        { attrs: { cols: "12", md: "3" } },
                                        [
                                          _c(
                                            VMenu,
                                            {
                                              ref: "menuDateIssuer",
                                              attrs: {
                                                readonly: _vm.ReadOnly,
                                                "close-on-content-click": false,
                                                transition: "scale-transition",
                                                "offset-y": "",
                                                "min-width": "290px",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function (ref) {
                                                      var on = ref.on
                                                      var attrs = ref.attrs
                                                      return [
                                                        _c(
                                                          VTextField,
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                attrs: {
                                                                  label:
                                                                    _vm.$t(
                                                                      "expedition_date"
                                                                    ),
                                                                  "prepend-inner-icon":
                                                                    "mdi-calendar",
                                                                  readonly: "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.dateIssuer,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.dateIssuer =
                                                                        $$v
                                                                    },
                                                                  expression:
                                                                    "dateIssuer",
                                                                },
                                                              },
                                                              "v-text-field",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          )
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                3988091193
                                              ),
                                              model: {
                                                value: _vm.menuDateIssuer,
                                                callback: function ($$v) {
                                                  _vm.menuDateIssuer = $$v
                                                },
                                                expression: "menuDateIssuer",
                                              },
                                            },
                                            [
                                              _c(VDatePicker, {
                                                staticClass: "mx-auto",
                                                on: {
                                                  change: function ($event) {
                                                    _vm.$refs.menuDateIssuer.save(
                                                      _vm.dateIssuer
                                                    )
                                                    _vm.menuDateIssuer = false
                                                  },
                                                },
                                                model: {
                                                  value: _vm.dateIssuer,
                                                  callback: function ($$v) {
                                                    _vm.dateIssuer = $$v
                                                  },
                                                  expression: "dateIssuer",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        VCol,
                                        { attrs: { cols: "12", md: "6" } },
                                        [
                                          _c(VTextField, {
                                            staticClass: "purple-input",
                                            attrs: {
                                              readonly: _vm.ReadOnly,
                                              label: _vm.$t("mother_name"),
                                            },
                                            model: {
                                              value: _vm.user.MotherName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.user,
                                                  "MotherName",
                                                  $$v
                                                )
                                              },
                                              expression: "user.MotherName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        VCol,
                                        { attrs: { cols: "12", md: "6" } },
                                        [
                                          _c(VTextField, {
                                            staticClass: "purple-input",
                                            attrs: {
                                              readonly: _vm.ReadOnly,
                                              label: _vm.$t("father_name"),
                                            },
                                            model: {
                                              value: _vm.user.FatherName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.user,
                                                  "FatherName",
                                                  $$v
                                                )
                                              },
                                              expression: "user.FatherName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        VCol,
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "legal_representative_question"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]),
                                          _c(
                                            VRow,
                                            { staticClass: "my-4 mx-12" },
                                            [
                                              _c(
                                                VRadioGroup,
                                                {
                                                  attrs: {
                                                    center: "",
                                                    readonly: _vm.ReadOnly,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.user
                                                        .HasLegalRepresentative,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.user,
                                                        "HasLegalRepresentative",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "user.HasLegalRepresentative",
                                                  },
                                                },
                                                [
                                                  _c(VRadio, {
                                                    attrs: { value: true },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "label",
                                                          fn: function () {
                                                            return [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticStyle: {
                                                                    color:
                                                                      "var(--dark)",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "yes"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      661222869
                                                    ),
                                                  }),
                                                  _c(VRadio, {
                                                    attrs: { value: false },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "label",
                                                          fn: function () {
                                                            return [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticStyle: {
                                                                    color:
                                                                      "var(--dark)",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "no"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      1351614811
                                                    ),
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.user.JuridicalType == _vm.JuridicalTypeEnum.LegalPerson
                    ? _c(
                        VExpansionPanel,
                        [
                          _c(VExpansionPanelHeader, [
                            _vm._v(
                              " " + _vm._s(_vm.$t("juridical_info")) + " "
                            ),
                          ]),
                          _c(
                            VExpansionPanelContent,
                            [
                              _vm.user.Type == _vm.userTypeEnum.Investor
                                ? _c(
                                    VRow,
                                    [
                                      _c(
                                        VCol,
                                        { attrs: { cols: "12", md: "4" } },
                                        [
                                          _c(VTextField, {
                                            staticClass: "purple-input",
                                            attrs: {
                                              readonly: _vm.ReadOnly,
                                              label: _vm.$t("company_name"),
                                            },
                                            model: {
                                              value: _vm.user.CompanyName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.user,
                                                  "CompanyName",
                                                  $$v
                                                )
                                              },
                                              expression: "user.CompanyName",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        VCol,
                                        { attrs: { cols: "12", md: "4" } },
                                        [
                                          _c(VTextField, {
                                            staticClass: "purple-input",
                                            attrs: {
                                              readonly: _vm.ReadOnly,
                                              label:
                                                _vm.$t("state_registration"),
                                            },
                                            model: {
                                              value: _vm.user.StateRegistration,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.user,
                                                  "StateRegistration",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "user.StateRegistration",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        VCol,
                                        { attrs: { cols: "12", md: "4" } },
                                        [
                                          _c(VTextField, {
                                            staticClass: "purple-input",
                                            attrs: {
                                              readonly: _vm.ReadOnly,
                                              label: _vm.$t(
                                                "main_economic_activity"
                                              ),
                                            },
                                            model: {
                                              value:
                                                _vm.user.MainEconomicActivity,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.user,
                                                  "MainEconomicActivity",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "user.MainEconomicActivity",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        VCol,
                                        { attrs: { cols: "12", md: "4" } },
                                        [
                                          _c(VTextField, {
                                            staticClass: "purple-input",
                                            attrs: {
                                              readonly: _vm.ReadOnly,
                                              label: _vm.$t("tax_address"),
                                            },
                                            model: {
                                              value: _vm.user.TaxAddress,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.user,
                                                  "TaxAddress",
                                                  $$v
                                                )
                                              },
                                              expression: "user.TaxAddress",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        VCol,
                                        { attrs: { cols: "12", md: "4" } },
                                        [
                                          _c(VTextField, {
                                            staticClass: "purple-input",
                                            attrs: {
                                              readonly: _vm.ReadOnly,
                                              label: _vm.$t("cnae"),
                                            },
                                            model: {
                                              value: _vm.user.CNAE,
                                              callback: function ($$v) {
                                                _vm.$set(_vm.user, "CNAE", $$v)
                                              },
                                              expression: "user.CNAE",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.user.JuridicalType == _vm.JuridicalTypeEnum.LegalPerson &&
                  _vm.user.HasLegalRepresentative
                    ? _c(
                        VExpansionPanel,
                        { attrs: { "on-panel": "" } },
                        [
                          _c(VExpansionPanelHeader, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("legal_representative_header")) +
                                " "
                            ),
                          ]),
                          _c(
                            VExpansionPanelContent,
                            [
                              _c(
                                VRow,
                                [
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "4" } },
                                    [
                                      _c(VTextField, {
                                        staticClass: "purple-input",
                                        attrs: {
                                          readonly: _vm.ReadOnly,
                                          label: _vm.$t("name"),
                                        },
                                        model: {
                                          value:
                                            _vm.user.LegalRepresentative.Name,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.user.LegalRepresentative,
                                              "Name",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "user.LegalRepresentative.Name",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "4" } },
                                    [
                                      _c(VTextField, {
                                        staticClass: "purple-input",
                                        attrs: {
                                          readonly: _vm.ReadOnly,
                                          label: _vm.$t("cpf"),
                                        },
                                        model: {
                                          value:
                                            _vm.user.LegalRepresentative
                                              .SocialNumber,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.user.LegalRepresentative,
                                              "SocialNumber",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "user.LegalRepresentative.SocialNumber",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "4" } },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("link_type")) +
                                            " "
                                        ),
                                      ]),
                                      _c(
                                        VRow,
                                        { staticClass: "my-4 mx-12" },
                                        [
                                          _c(
                                            VRadioGroup,
                                            {
                                              attrs: {
                                                center: "",
                                                readonly: _vm.ReadOnly,
                                              },
                                              model: {
                                                value:
                                                  _vm.user.LegalRepresentative
                                                    .LinkType,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.user
                                                      .LegalRepresentative,
                                                    "LinkType",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "user.LegalRepresentative.LinkType",
                                              },
                                            },
                                            [
                                              _c(VRadio, {
                                                attrs: { value: 0 },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "label",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color:
                                                                  "var(--dark)",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "legal_representative"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  4243133909
                                                ),
                                              }),
                                              _c(VRadio, {
                                                attrs: { value: 1 },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "label",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color:
                                                                  "var(--dark)",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "attorney"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  3204358580
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "4" } },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("marital_status")) +
                                            " "
                                        ),
                                      ]),
                                      _c(
                                        VRow,
                                        { staticClass: "my-4 mx-12" },
                                        [
                                          _c(
                                            VRadioGroup,
                                            {
                                              attrs: {
                                                center: "",
                                                readonly: _vm.ReadOnly,
                                              },
                                              model: {
                                                value:
                                                  _vm.user.LegalRepresentative
                                                    .MaritalStatus,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.user
                                                      .LegalRepresentative,
                                                    "MaritalStatus",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "user.LegalRepresentative.MaritalStatus",
                                              },
                                            },
                                            [
                                              _c(VRadio, {
                                                attrs: { value: 0 },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "label",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color:
                                                                  "var(--dark)",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "single"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  127828032
                                                ),
                                              }),
                                              _c(VRadio, {
                                                attrs: { value: 1 },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "label",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color:
                                                                  "var(--dark)",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "married"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  705419646
                                                ),
                                              }),
                                              _c(VRadio, {
                                                attrs: { value: 2 },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "label",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color:
                                                                  "var(--dark)",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "widower"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  1329492943
                                                ),
                                              }),
                                              _c(VRadio, {
                                                attrs: { value: 3 },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "label",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color:
                                                                  "var(--dark)",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "divorced"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  2925929822
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "4" } },
                                    [
                                      _c(VTextField, {
                                        staticClass: "purple-input",
                                        attrs: {
                                          readonly: _vm.ReadOnly,
                                          label: _vm.$t("nationality"),
                                        },
                                        model: {
                                          value:
                                            _vm.user.LegalRepresentative
                                              .Nationality,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.user.LegalRepresentative,
                                              "Nationality",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "user.LegalRepresentative.Nationality",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "4" } },
                                    [
                                      _c(VTextField, {
                                        staticClass: "purple-input",
                                        attrs: {
                                          readonly: _vm.ReadOnly,
                                          label: _vm.$t("profession"),
                                        },
                                        model: {
                                          value:
                                            _vm.user.LegalRepresentative
                                              .Profession,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.user.LegalRepresentative,
                                              "Profession",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "user.LegalRepresentative.Profession",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "3" } },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("document_type")) +
                                            " "
                                        ),
                                      ]),
                                      _c(
                                        VRow,
                                        { staticClass: "my-4 mx-12" },
                                        [
                                          _c(
                                            VRadioGroup,
                                            {
                                              attrs: {
                                                center: "",
                                                readonly: _vm.ReadOnly,
                                              },
                                              model: {
                                                value:
                                                  _vm.user.LegalRepresentative
                                                    .DocumentType,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.user
                                                      .LegalRepresentative,
                                                    "DocumentType",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "user.LegalRepresentative.DocumentType",
                                              },
                                            },
                                            [
                                              _c(VRadio, {
                                                attrs: { value: 0 },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "label",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color:
                                                                  "var(--dark)",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t("rg")
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  4117849935
                                                ),
                                              }),
                                              _c(VRadio, {
                                                attrs: { value: 1 },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "label",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color:
                                                                  "var(--dark)",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t("cnh")
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  3985088063
                                                ),
                                              }),
                                              _c(VRadio, {
                                                attrs: { value: 2 },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "label",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color:
                                                                  "var(--dark)",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "passport"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  3524110290
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "4" } },
                                    [
                                      _c(VTextField, {
                                        staticClass: "purple-input",
                                        attrs: {
                                          readonly: _vm.ReadOnly,
                                          label: _vm.$t("document_number"),
                                        },
                                        model: {
                                          value:
                                            _vm.user.LegalRepresentative
                                              .DocumentNumber,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.user.LegalRepresentative,
                                              "DocumentNumber",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "user.LegalRepresentative.DocumentNumber",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "4" } },
                                    [
                                      _c(VTextField, {
                                        staticClass: "purple-input",
                                        attrs: {
                                          readonly: _vm.ReadOnly,
                                          label: _vm.$t("issuing_body"),
                                        },
                                        model: {
                                          value:
                                            _vm.user.LegalRepresentative.Issuer,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.user.LegalRepresentative,
                                              "Issuer",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "user.LegalRepresentative.Issuer",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "4" } },
                                    [
                                      _c(VTextField, {
                                        staticClass: "purple-input",
                                        attrs: {
                                          readonly: _vm.ReadOnly,
                                          label: _vm.$t("state"),
                                        },
                                        model: {
                                          value:
                                            _vm.user.LegalRepresentative
                                              .IssuerState,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.user.LegalRepresentative,
                                              "IssuerState",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "user.LegalRepresentative.IssuerState",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "4" } },
                                    [
                                      _c(VTextField, {
                                        staticClass: "purple-input",
                                        attrs: {
                                          readonly: _vm.ReadOnly,
                                          label: _vm.$t("state"),
                                        },
                                        model: {
                                          value:
                                            _vm.user.LegalRepresentative
                                              .IssuerState,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.user.LegalRepresentative,
                                              "IssuerState",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "user.LegalRepresentative.IssuerState",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "3" } },
                                    [
                                      _c(
                                        VMenu,
                                        {
                                          ref: "menuDateLegalRepresentative",
                                          attrs: {
                                            readonly: _vm.ReadOnly,
                                            "close-on-content-click": false,
                                            "return-value":
                                              _vm.dateLegalRepresentative,
                                            transition: "scale-transition",
                                            "offset-y": "",
                                            "min-width": "290px",
                                          },
                                          on: {
                                            "update:returnValue": function (
                                              $event
                                            ) {
                                              _vm.dateLegalRepresentative =
                                                $event
                                            },
                                            "update:return-value": function (
                                              $event
                                            ) {
                                              _vm.dateLegalRepresentative =
                                                $event
                                            },
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function (ref) {
                                                  var on = ref.on
                                                  var attrs = ref.attrs
                                                  return [
                                                    _c(
                                                      VTextField,
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            attrs: {
                                                              label:
                                                                _vm.$t(
                                                                  "expedition_date"
                                                                ),
                                                              "prepend-inner-icon":
                                                                "mdi-calendar",
                                                              readonly: "",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.dateLegalRepresentative,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.dateLegalRepresentative =
                                                                    $$v
                                                                },
                                                              expression:
                                                                "dateLegalRepresentative",
                                                            },
                                                          },
                                                          "v-text-field",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      )
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            3997803586
                                          ),
                                          model: {
                                            value:
                                              _vm.menuDateLegalRepresentative,
                                            callback: function ($$v) {
                                              _vm.menuDateLegalRepresentative =
                                                $$v
                                            },
                                            expression:
                                              "menuDateLegalRepresentative",
                                          },
                                        },
                                        [
                                          _c(VDatePicker, {
                                            staticClass: "mx-auto",
                                            on: {
                                              change: function ($event) {
                                                _vm.$refs.menuDateLegalRepresentative.save(
                                                  _vm.dateLegalRepresentative
                                                )
                                                _vm.menuDateLegalRepresentative = false
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.dateLegalRepresentative,
                                              callback: function ($$v) {
                                                _vm.dateLegalRepresentative =
                                                  $$v
                                              },
                                              expression:
                                                "dateLegalRepresentative",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "4" } },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "non_resident_investor_question"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]),
                                      _c(
                                        VRow,
                                        { staticClass: "my-4 mx-12" },
                                        [
                                          _c(
                                            VRadioGroup,
                                            {
                                              attrs: {
                                                center: "",
                                                readonly: _vm.ReadOnly,
                                              },
                                              model: {
                                                value:
                                                  _vm.user
                                                    .HasNonResidentInvestor,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.user,
                                                    "HasNonResidentInvestor",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "user.HasNonResidentInvestor",
                                              },
                                            },
                                            [
                                              _c(VRadio, {
                                                attrs: { value: false },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "label",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color:
                                                                  "var(--dark)",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t("no")
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  1351614811
                                                ),
                                              }),
                                              _c(VRadio, {
                                                attrs: { value: true },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "label",
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                color:
                                                                  "var(--dark)",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t("yes")
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  661222869
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.user.JuridicalType == _vm.JuridicalTypeEnum.LegalPerson &&
                  _vm.user.HasNonResidentInvestor
                    ? _c(
                        VExpansionPanel,
                        [
                          _c(VExpansionPanelHeader, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("non_resident_investor")) +
                                " "
                            ),
                          ]),
                          _c(
                            VExpansionPanelContent,
                            [
                              _c(
                                VRow,
                                [
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "12" } },
                                    [
                                      _c(VTextField, {
                                        staticClass: "purple-input",
                                        attrs: {
                                          readonly: _vm.ReadOnly,
                                          label: _vm.$t("rde"),
                                        },
                                        model: {
                                          value:
                                            _vm.user.NonResidentInvestor.RDE,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.user.NonResidentInvestor,
                                              "RDE",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "user.NonResidentInvestor.RDE",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "6" } },
                                    [
                                      _c(VTextField, {
                                        staticClass: "purple-input",
                                        attrs: {
                                          readonly: _vm.ReadOnly,
                                          label: _vm.$t(
                                            "country_representative"
                                          ),
                                        },
                                        model: {
                                          value:
                                            _vm.user.NonResidentInvestor
                                              .CountryLegalRepresentative,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.user.NonResidentInvestor,
                                              "CountryLegalRepresentative",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "user.NonResidentInvestor.CountryLegalRepresentative",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "6" } },
                                    [
                                      _c(VTextField, {
                                        staticClass: "purple-input",
                                        attrs: {
                                          readonly: _vm.ReadOnly,
                                          label: _vm.$t("cpf"),
                                        },
                                        model: {
                                          value:
                                            _vm.user.NonResidentInvestor
                                              .CountryLegalRepresentativeSocialNumber,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.user.NonResidentInvestor,
                                              "CountryLegalRepresentativeSocialNumber",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "\n                  user.NonResidentInvestor\n                    .CountryLegalRepresentativeSocialNumber\n                ",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "6" } },
                                    [
                                      _c(VTextField, {
                                        staticClass: "purple-input",
                                        attrs: {
                                          readonly: _vm.ReadOnly,
                                          label: _vm.$t("tax_representative"),
                                        },
                                        model: {
                                          value:
                                            _vm.user.NonResidentInvestor
                                              .TaxRepresentative,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.user.NonResidentInvestor,
                                              "TaxRepresentative",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "user.NonResidentInvestor.TaxRepresentative",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "6" } },
                                    [
                                      _c(VTextField, {
                                        staticClass: "purple-input",
                                        attrs: {
                                          readonly: _vm.ReadOnly,
                                          label: _vm.$t("cpf"),
                                        },
                                        model: {
                                          value:
                                            _vm.user.NonResidentInvestor
                                              .TaxRepresentativeSocialNumber,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.user.NonResidentInvestor,
                                              "TaxRepresentativeSocialNumber",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "\n                  user.NonResidentInvestor.TaxRepresentativeSocialNumber\n                ",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.user.JuridicalType == _vm.JuridicalTypeEnum.LegalPerson
                    ? _c(
                        VExpansionPanel,
                        [
                          _c(VExpansionPanelHeader, [
                            _vm._v(
                              " " + _vm._s(_vm.$t("stock_position")) + " "
                            ),
                          ]),
                          _c(
                            VExpansionPanelContent,
                            [
                              !_vm.ReadOnly
                                ? _c(
                                    VBtn,
                                    {
                                      staticClass: "mt-8 mr-9",
                                      attrs: {
                                        color: "primary",
                                        small: "",
                                        rounded: "",
                                        absolute: "",
                                        fab: "",
                                        top: "",
                                        right: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.open_stock_position_dialog(
                                            null
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(VIcon, { attrs: { small: "" } }, [
                                        _vm._v("mdi-plus"),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                VRow,
                                [
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "6" } },
                                    [
                                      _c(VTextField, {
                                        staticClass: "purple-input",
                                        attrs: {
                                          readonly: _vm.ReadOnly,
                                          type: "number",
                                          prefix: "R$",
                                          label: _vm.$t("net_worth"),
                                        },
                                        on: {
                                          change: function ($event) {
                                            _vm.user.NetWorth = parseFloat(
                                              _vm.user.NetWorth
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.user.NetWorth,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.user, "NetWorth", $$v)
                                          },
                                          expression: "user.NetWorth",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "6" } },
                                    [
                                      _c(VTextField, {
                                        staticClass: "purple-input",
                                        attrs: {
                                          readonly: _vm.ReadOnly,
                                          type: "number",
                                          prefix: "R$",
                                          label: _vm.$t(
                                            "average_monthly_revenue"
                                          ),
                                        },
                                        on: {
                                          change: function ($event) {
                                            _vm.user.AverageMonthlyRevenue =
                                              parseFloat(
                                                _vm.user.AverageMonthlyRevenue
                                              )
                                          },
                                        },
                                        model: {
                                          value: _vm.user.AverageMonthlyRevenue,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.user,
                                              "AverageMonthlyRevenue",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "user.AverageMonthlyRevenue",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                VSimpleTable,
                                { staticStyle: { width: "100%" } },
                                [
                                  _c("thead", [
                                    _c("tr", [
                                      _c("th", { staticClass: "text-center" }, [
                                        _vm._v(_vm._s(_vm.$t("name"))),
                                      ]),
                                      _c("th", { staticClass: "text-center" }, [
                                        _vm._v(_vm._s(_vm.$t("cpf"))),
                                      ]),
                                      _c("th", { staticClass: "text-center" }, [
                                        _vm._v(_vm._s(_vm.$t("nationality"))),
                                      ]),
                                      _c("th", { staticClass: "text-center" }, [
                                        _vm._v(_vm._s(_vm.$t("capital"))),
                                      ]),
                                      !_vm.ReadOnly
                                        ? _c(
                                            "th",
                                            { staticClass: "text-center" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.$t("options")) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]),
                                  ]),
                                  _c(
                                    "tbody",
                                    _vm._l(
                                      _vm.user.StockPositions,
                                      function (sp, index) {
                                        return _c("tr", { key: index }, [
                                          _c(
                                            "td",
                                            { staticClass: "text-center" },
                                            [_vm._v(_vm._s(sp.Name))]
                                          ),
                                          _c(
                                            "td",
                                            { staticClass: "text-center" },
                                            [_vm._v(_vm._s(sp.RegistryNumber))]
                                          ),
                                          _c(
                                            "td",
                                            { staticClass: "text-center" },
                                            [_vm._v(_vm._s(sp.Nationality))]
                                          ),
                                          _c(
                                            "td",
                                            { staticClass: "text-center" },
                                            [_vm._v(_vm._s(sp.Capital) + "%")]
                                          ),
                                          !_vm.ReadOnly
                                            ? _c(
                                                "td",
                                                { staticClass: "text-center" },
                                                [
                                                  _c(
                                                    VBtn,
                                                    {
                                                      staticClass:
                                                        "px-2 ml-1 secondary",
                                                      attrs: {
                                                        "min-width": "0",
                                                        small: "",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.open_stock_position_dialog(
                                                            sp
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        VIcon,
                                                        {
                                                          attrs: { small: "" },
                                                        },
                                                        [_vm._v("mdi-pencil")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    VBtn,
                                                    {
                                                      staticClass: "px-2 ml-1",
                                                      attrs: {
                                                        color: "red",
                                                        "min-width": "0",
                                                        small: "",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.open_delete_dialog_stock_position(
                                                            sp
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        VIcon,
                                                        {
                                                          attrs: { small: "" },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "mdi-trash-can"
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ])
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.user.JuridicalType == _vm.JuridicalTypeEnum.LegalPerson
                    ? _c(
                        VExpansionPanel,
                        [
                          _c(VExpansionPanelHeader, [
                            _vm._v(
                              " " + _vm._s(_vm.$t("equity_holding")) + " "
                            ),
                          ]),
                          _c(
                            VExpansionPanelContent,
                            [
                              _c(
                                VRow,
                                [
                                  !_vm.ReadOnly
                                    ? _c(
                                        VBtn,
                                        {
                                          staticClass: "mt-8 mr-9",
                                          attrs: {
                                            color: "primary",
                                            small: "",
                                            rounded: "",
                                            absolute: "",
                                            fab: "",
                                            top: "",
                                            right: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.open_equity_holding_dialog(
                                                null
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            VIcon,
                                            { attrs: { small: "" } },
                                            [_vm._v("mdi-plus")]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    VSimpleTable,
                                    { staticStyle: { width: "100%" } },
                                    [
                                      _c("thead", [
                                        _c("tr", [
                                          _c(
                                            "th",
                                            { staticClass: "text-center" },
                                            [_vm._v(_vm._s(_vm.$t("name")))]
                                          ),
                                          _c(
                                            "th",
                                            { staticClass: "text-center" },
                                            [_vm._v(_vm._s(_vm.$t("cpf")))]
                                          ),
                                          _c(
                                            "th",
                                            { staticClass: "text-center" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("nationality"))
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "th",
                                            { staticClass: "text-center" },
                                            [_vm._v(_vm._s(_vm.$t("capital")))]
                                          ),
                                          _c(
                                            "th",
                                            { staticClass: "text-center" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "is_politically_exposed"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          !_vm.ReadOnly
                                            ? _c(
                                                "th",
                                                { staticClass: "text-center" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t("options")
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]),
                                      ]),
                                      _c(
                                        "tbody",
                                        _vm._l(
                                          _vm.user.EquityHoldings,
                                          function (eh, index) {
                                            return _c("tr", { key: index }, [
                                              _c(
                                                "td",
                                                { staticClass: "text-center" },
                                                [_vm._v(_vm._s(eh.Name))]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "text-center" },
                                                [
                                                  _vm._v(
                                                    _vm._s(eh.RegistryNumber)
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "text-center" },
                                                [_vm._v(_vm._s(eh.Nationality))]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "text-center" },
                                                [
                                                  _vm._v(
                                                    _vm._s(eh.Capital) + "%"
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "td",
                                                { staticClass: "text-center" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        eh.PoliticallyExposed
                                                          ? _vm.$t("yes")
                                                          : _vm.$t("no")
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              !_vm.ReadOnly
                                                ? _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "text-center",
                                                    },
                                                    [
                                                      _c(
                                                        VBtn,
                                                        {
                                                          staticClass:
                                                            "px-2 ml-1 secondary",
                                                          attrs: {
                                                            "min-width": "0",
                                                            small: "",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.open_equity_holding_dialog(
                                                                eh
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            VIcon,
                                                            {
                                                              attrs: {
                                                                small: "",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "mdi-pencil"
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        VBtn,
                                                        {
                                                          staticClass:
                                                            "px-2 ml-1",
                                                          attrs: {
                                                            color: "red",
                                                            "min-width": "0",
                                                            small: "",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.open_delete_dialog_equity_holding(
                                                                eh
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            VIcon,
                                                            {
                                                              attrs: {
                                                                small: "",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "mdi-trash-can"
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ])
                                          }
                                        ),
                                        0
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    VExpansionPanel,
                    [
                      _vm.user.JuridicalType ==
                      _vm.JuridicalTypeEnum.NaturalPerson
                        ? _c(VExpansionPanelHeader, [
                            _vm._v(
                              " " + _vm._s(_vm.$t("professional_info")) + " "
                            ),
                          ])
                        : _vm._e(),
                      _c(
                        VExpansionPanelContent,
                        [
                          _vm.user.Type == _vm.userTypeEnum.Investor
                            ? _c(
                                VRow,
                                [
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "4" } },
                                    [
                                      _c(VAutocomplete, {
                                        staticClass: "field",
                                        attrs: {
                                          readonly: _vm.ReadOnly,
                                          filter: _vm.filterObject,
                                          items: _vm.profession_options,
                                          "item-text":
                                            _vm.translateProfessionOptions,
                                          "item-value": "id",
                                          rules: [_vm.required],
                                          label: _vm.$t("your_profession"),
                                          id: "profession_input",
                                        },
                                        on: { keyup: _vm.getProfession },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "no-data",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    VListItem,
                                                    [
                                                      _c(VListItemTitle, [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.profession_text_follback
                                                                ? _vm.$t(
                                                                    "write_your_profession"
                                                                  )
                                                                : _vm.$t(
                                                                    "profession_not_found"
                                                                  )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          false,
                                          3741272751
                                        ),
                                        model: {
                                          value: _vm.user.ProfessionId,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.user,
                                              "ProfessionId",
                                              $$v
                                            )
                                          },
                                          expression: "user.ProfessionId",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "4" } },
                                    [
                                      _c(VTextField, {
                                        staticClass: "purple-input",
                                        attrs: {
                                          readonly: _vm.ReadOnly,
                                          label: _vm.$t(
                                            "professional_occupation"
                                          ),
                                        },
                                        model: {
                                          value:
                                            _vm.user.ProfessionalOccupation,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.user,
                                              "ProfessionalOccupation",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "user.ProfessionalOccupation",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "4" } },
                                    [
                                      _c(VTextField, {
                                        staticClass: "purple-input",
                                        attrs: {
                                          readonly: _vm.ReadOnly,
                                          label: _vm.$t("employer"),
                                        },
                                        model: {
                                          value: _vm.user.Employer,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.user, "Employer", $$v)
                                          },
                                          expression: "user.Employer",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "6" } },
                                    [
                                      _c(VTextField, {
                                        staticClass: "purple-input",
                                        attrs: {
                                          readonly: _vm.ReadOnly,
                                          label: _vm.$t("company_id"),
                                        },
                                        model: {
                                          value: _vm.user.EmployerNumber,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.user,
                                              "EmployerNumber",
                                              $$v
                                            )
                                          },
                                          expression: "user.EmployerNumber",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "6" } },
                                    [
                                      _c(VTextField, {
                                        staticClass: "purple-input",
                                        attrs: {
                                          readonly: _vm.ReadOnly,
                                          label: _vm.$t("phone"),
                                        },
                                        model: {
                                          value: _vm.user.EmployerPhone,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.user,
                                              "EmployerPhone",
                                              $$v
                                            )
                                          },
                                          expression: "user.EmployerPhone",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "12" } },
                                    [
                                      _c(VTextField, {
                                        staticClass: "purple-input",
                                        attrs: {
                                          readonly: _vm.ReadOnly,
                                          label: _vm.$t("address"),
                                        },
                                        model: {
                                          value: _vm.user.EmployerAddress,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.user,
                                              "EmployerAddress",
                                              $$v
                                            )
                                          },
                                          expression: "user.EmployerAddress",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12", md: "12" } },
                                    [
                                      _c(VTextField, {
                                        staticClass: "purple-input",
                                        attrs: {
                                          readonly: _vm.ReadOnly,
                                          type: "number",
                                          label: _vm.$t("monthly_income"),
                                        },
                                        model: {
                                          value: _vm.user.MonthlyIncome,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.user,
                                              "MonthlyIncome",
                                              $$v
                                            )
                                          },
                                          expression: "user.MonthlyIncome",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.user.JuridicalType == _vm.JuridicalTypeEnum.NaturalPerson
                    ? _c(
                        VExpansionPanel,
                        [
                          _c(VExpansionPanelHeader, [
                            _vm._v(" " + _vm._s(_vm.$t("statements")) + " "),
                          ]),
                          _c(
                            VExpansionPanelContent,
                            [
                              _vm.user.Type == _vm.userTypeEnum.Investor
                                ? _c(
                                    VRow,
                                    [
                                      _c(
                                        VCol,
                                        { attrs: { cols: "12", md: "3" } },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("marital_status")
                                                ) +
                                                " "
                                            ),
                                          ]),
                                          _c(
                                            VRow,
                                            { staticClass: "my-4 mx-12" },
                                            [
                                              _c(
                                                VRadioGroup,
                                                {
                                                  attrs: {
                                                    center: "",
                                                    readonly: _vm.ReadOnly,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.user.MaritalStatus,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.user,
                                                        "MaritalStatus",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "user.MaritalStatus",
                                                  },
                                                },
                                                [
                                                  _c(VRadio, {
                                                    attrs: { value: 0 },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "label",
                                                          fn: function () {
                                                            return [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticStyle: {
                                                                    color:
                                                                      "var(--dark)",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "single"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      127828032
                                                    ),
                                                  }),
                                                  _c(VRadio, {
                                                    attrs: { value: 1 },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "label",
                                                          fn: function () {
                                                            return [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticStyle: {
                                                                    color:
                                                                      "var(--dark)",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "married"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      705419646
                                                    ),
                                                  }),
                                                  _c(VRadio, {
                                                    attrs: { value: 2 },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "label",
                                                          fn: function () {
                                                            return [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticStyle: {
                                                                    color:
                                                                      "var(--dark)",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "widower"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      1329492943
                                                    ),
                                                  }),
                                                  _c(VRadio, {
                                                    attrs: { value: 3 },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "label",
                                                          fn: function () {
                                                            return [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticStyle: {
                                                                    color:
                                                                      "var(--dark)",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "divorced"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      2925929822
                                                    ),
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        VCol,
                                        { attrs: { cols: "12", md: "3" } },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              " " + _vm._s(_vm.$t("sex")) + " "
                                            ),
                                          ]),
                                          _c(
                                            VRow,
                                            { staticClass: "my-4 mx-12" },
                                            [
                                              _c(
                                                VRadioGroup,
                                                {
                                                  attrs: {
                                                    center: "",
                                                    readonly: _vm.ReadOnly,
                                                  },
                                                  model: {
                                                    value: _vm.user.Sex,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.user,
                                                        "Sex",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "user.Sex",
                                                  },
                                                },
                                                [
                                                  _c(VRadio, {
                                                    attrs: { value: 0 },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "label",
                                                          fn: function () {
                                                            return [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticStyle: {
                                                                    color:
                                                                      "var(--dark)",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "woman"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      496421856
                                                    ),
                                                  }),
                                                  _c(VRadio, {
                                                    attrs: { value: 1 },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "label",
                                                          fn: function () {
                                                            return [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticStyle: {
                                                                    color:
                                                                      "var(--dark)",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "man"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      3434246680
                                                    ),
                                                  }),
                                                  _c(VRadio, {
                                                    attrs: { value: 2 },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "label",
                                                          fn: function () {
                                                            return [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticStyle: {
                                                                    color:
                                                                      "var(--dark)",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "other"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      1226935582
                                                    ),
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        VCol,
                                        { attrs: { cols: "12", md: "3" } },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "is_politically_exposed"
                                                  )
                                                ) +
                                                " "
                                            ),
                                          ]),
                                          _c(
                                            VRow,
                                            { staticClass: "my-4 mx-12" },
                                            [
                                              _c(
                                                VRadioGroup,
                                                {
                                                  attrs: {
                                                    center: "",
                                                    readonly: _vm.ReadOnly,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.user
                                                        .PoliticallyExposed,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.user,
                                                        "PoliticallyExposed",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "user.PoliticallyExposed",
                                                  },
                                                },
                                                [
                                                  _c(VRadio, {
                                                    attrs: { value: true },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "label",
                                                          fn: function () {
                                                            return [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticStyle: {
                                                                    color:
                                                                      "var(--dark)",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "yes"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      661222869
                                                    ),
                                                  }),
                                                  _c(VRadio, {
                                                    attrs: { value: false },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "label",
                                                          fn: function () {
                                                            return [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticStyle: {
                                                                    color:
                                                                      "var(--dark)",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "no"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      1351614811
                                                    ),
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        VCol,
                                        { attrs: { cols: "12", md: "3" } },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("is_us_person")) +
                                                " "
                                            ),
                                          ]),
                                          _c(
                                            VRow,
                                            { staticClass: "my-4 mx-12" },
                                            [
                                              _c(
                                                VRadioGroup,
                                                {
                                                  attrs: {
                                                    center: "",
                                                    readonly: _vm.ReadOnly,
                                                  },
                                                  model: {
                                                    value: _vm.user.USPerson,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.user,
                                                        "USPerson",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "user.USPerson",
                                                  },
                                                },
                                                [
                                                  _c(VRadio, {
                                                    attrs: { value: true },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "label",
                                                          fn: function () {
                                                            return [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticStyle: {
                                                                    color:
                                                                      "var(--dark)",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "yes"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      661222869
                                                    ),
                                                  }),
                                                  _c(VRadio, {
                                                    attrs: { value: false },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "label",
                                                          fn: function () {
                                                            return [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticStyle: {
                                                                    color:
                                                                      "var(--dark)",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "no"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      1351614811
                                                    ),
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    VExpansionPanel,
                    [
                      _vm.user.IsAlpha
                        ? _c(VExpansionPanelHeader, [
                            _vm._v(
                              " " + _vm._s(_vm.$tc("alpha_investor", 1)) + " "
                            ),
                          ])
                        : _vm._e(),
                      _vm.user.IsAlpha
                        ? _c(
                            VExpansionPanelContent,
                            [
                              _c(
                                VRow,
                                [
                                  _c(
                                    VCol,
                                    {
                                      staticClass: "pb-0",
                                      attrs: { cols: "12", md: "6" },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(_vm.$t("invest_value"))),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    VCol,
                                    {
                                      staticClass: "pb-0",
                                      attrs: { cols: "12", md: "6" },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$t("selected_portfolio"))
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                VRow,
                                [
                                  _c(
                                    VCol,
                                    {
                                      staticClass: "pt-0",
                                      attrs: { cols: "12", md: "6" },
                                    },
                                    [
                                      _c("money", {
                                        staticClass: "register-text-field",
                                        class: {
                                          "invalid-field":
                                            !_vm.user.InvestValue ||
                                            _vm.user.InvestValue <
                                              _vm.portfolioSelected
                                                .MinimumValue,
                                        },
                                        attrs: {
                                          prefix: "R$",
                                          thousand: ".",
                                          thousands: ".",
                                          decimal: ",",
                                        },
                                        model: {
                                          value: _vm.user.InvestValue,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.user,
                                              "InvestValue",
                                              $$v
                                            )
                                          },
                                          expression: "user.InvestValue",
                                        },
                                      }),
                                      !_vm.portfolioLoading &&
                                      _vm.user.InvestValue <
                                        _vm.portfolioSelected.MinimumValue
                                        ? _c(
                                            "p",
                                            {
                                              staticClass: "mb-4 mt-n4 ml-2",
                                              staticStyle: {
                                                color: "red",
                                                "font-size": "10px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "alpha_register_minimum",
                                                      {
                                                        value:
                                                          _vm.portfolioSelected
                                                            .MinimumValueLabel,
                                                      }
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    {
                                      staticClass: "pt-0",
                                      attrs: { cols: "12", md: "6" },
                                    },
                                    [
                                      _c(VSelect, {
                                        attrs: {
                                          items: _vm.portfolioOptions,
                                          loading: _vm.portfolioLoading,
                                          "item-text": "number",
                                          color: "black",
                                          "return-object": "",
                                          outlined: "",
                                          dense: "",
                                          rules: [_vm.required],
                                          readonly: "",
                                        },
                                        model: {
                                          value: _vm.portfolioSelected,
                                          callback: function ($$v) {
                                            _vm.portfolioSelected = $$v
                                          },
                                          expression: "portfolioSelected",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    [
                                      _c(VTextField, {
                                        directives: [
                                          {
                                            name: "mask",
                                            rawName: "v-mask",
                                            value: ["##/##/####"],
                                            expression: "['##/##/####']",
                                          },
                                        ],
                                        attrs: {
                                          label: _vm.$t("alpha_register_date"),
                                          color: "black",
                                          dense: "",
                                          outlined: "",
                                          rules: [
                                            _vm.vs.validDate(
                                              _vm.alpha_register_date
                                            ),
                                          ],
                                          "prepend-inner-icon": "mdi-calendar",
                                        },
                                        on: {
                                          blur: function ($event) {
                                            _vm.user.AlphaRegisterDate =
                                              _vm.gs.parseDate(
                                                _vm.alpha_register_date
                                              )
                                          },
                                        },
                                        model: {
                                          value: _vm.alpha_register_date,
                                          callback: function ($$v) {
                                            _vm.alpha_register_date = $$v
                                          },
                                          expression: "alpha_register_date",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("label", [
                                        _vm._v(_vm._s(_vm.$t("obs"))),
                                      ]),
                                      _c(VTextarea, {
                                        attrs: { dense: "", outlined: "" },
                                        model: {
                                          value: _vm.user.AlphaInvestorAdmNotes,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.user,
                                              "AlphaInvestorAdmNotes",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "user.AlphaInvestorAdmNotes",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          !_vm.ReadOnly
            ? _c(
                VCardActions,
                { staticClass: "pl-0 dxa_modal_actions" },
                [
                  _c(
                    VBtn,
                    {
                      staticClass: "dxa_modal_btnSuccess",
                      attrs: {
                        color: "primary",
                        "min-width": "100",
                        loading: _vm.loading,
                        type: "submit",
                        "data-test": "Admin:Basic:SaveUser",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("save")) + " ")]
                  ),
                  _c(
                    VBtn,
                    {
                      staticClass: "dxa_modal_btnError",
                      attrs: { color: "secondary", "min-width": "100" },
                      on: {
                        click: function ($event) {
                          return _vm.$router.go(-1)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("go_back")))]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.dialogAddPartnerB2bCompany
        ? _c(
            VDialog,
            {
              model: {
                value: _vm.dialogAddPartnerB2bCompany,
                callback: function ($$v) {
                  _vm.dialogAddPartnerB2bCompany = $$v
                },
                expression: "dialogAddPartnerB2bCompany",
              },
            },
            [
              _c("DialogAddOrEditPartnerB2b", {
                attrs: { partnerB2bContent: _vm.editPartnerB2bContent },
                on: {
                  closeDialog: _vm.handleCloseDialog,
                  saved: _vm.handleSavedPartnerB2b,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.dialogEquityHolding
        ? _c(
            VDialog,
            {
              model: {
                value: _vm.dialogEquityHolding,
                callback: function ($$v) {
                  _vm.dialogEquityHolding = $$v
                },
                expression: "dialogEquityHolding",
              },
            },
            [
              _c("AddEquityHolding", {
                attrs: { Obj: _vm.equityHolding, UserId: _vm.user.Id },
                on: {
                  update: _vm.update_equity_holding,
                  close: function ($event) {
                    _vm.dialogEquityHolding = false
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.dialogStockPosition
        ? _c(
            VDialog,
            {
              model: {
                value: _vm.dialogStockPosition,
                callback: function ($$v) {
                  _vm.dialogStockPosition = $$v
                },
                expression: "dialogStockPosition",
              },
            },
            [
              _c("AddStockPosition", {
                attrs: { Obj: _vm.stockPosition, UserId: _vm.user.Id },
                on: {
                  update: _vm.update_stock_position,
                  close: function ($event) {
                    _vm.dialogStockPosition = false
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.deleteDialog
        ? _c(
            VDialog,
            {
              model: {
                value: _vm.deleteDialog,
                callback: function ($$v) {
                  _vm.deleteDialog = $$v
                },
                expression: "deleteDialog",
              },
            },
            [
              _c("DeleteConfirmationModal", {
                attrs: {
                  obj: _vm.equityHolding
                    ? _vm.equityHolding
                    : _vm.stockPosition,
                  name: _vm.equityHolding
                    ? _vm.$t("this_equity_holding")
                    : _vm.$t("this_stock_position"),
                },
                on: {
                  close: function ($event) {
                    _vm.deleteDialog = false
                  },
                  delete: function (obj) {
                    return _vm.equityHolding
                      ? _vm.delete_equity_holding(obj)
                      : _vm.delete_stock_position(obj)
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }